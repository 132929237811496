<template>
    <div>
        <mynavbar></mynavbar>
        <myrightsb></myrightsb>
        <!--<amplify-sign-in></amplify-sign-in>
        <amplify-sign-out></amplify-sign-out>
        <p>{{user.username}}</p>-->
        <div class="pt-2">
            <router-view class="mt-5"></router-view>
        </div>

    </div>
</template>

<script>
    import NavBar from '@/components/Menu/NavBar.vue';
    import SideBarRight from '@/components/Menu/SideBarRight.vue';
    //    import { components } from 'aws-amplify-vue';
    //    import { Auth } from 'aws-amplify';
    export default {
        data: function () {
            return {
                message: 'Message from App.vue',
                //                user: {}
            }
        },
        //computed: {
        //    bgImage() {
        //        return {
        //            'backgroundImage': 'url(https://dev.log4all.com/images/4b6d59f7-82b0-40ae-9e13-03bea93ee30b/nfj_small.jpg)',
        //            'height': '100vh',
        //            'background-repeat': 'no-repeat',
        //            'background-size': 'cover'
        //        }
        //    }
        //},
        components: {
            mynavbar: NavBar,
            myrightsb: SideBarRight,
            //            components,
            //            Auth
        },
        mounted() {
            //if (this.$router.path !== '/') this.$router.push('/');

            //this.$Amplify.Auth.currentAuthenticatedUser().then(data => {
            //    if (data && data.signInUserSession) {
            //        this.user = data;
            //    }

            //}).catch((e) => {
            //    this.user = {};
            //});
        }
    }
</script>

<style>
</style>

<style lang="scss">
    /*Import custom SASS variable overrides, or alternatively
    define your variable overrides here instead*/
    @import './assets/_variables.scss';

    /*Import Bootstrap and BootstrapVue source SCSS files*/
    @import '~bootstrap/scss/bootstrap.scss';
    @import '~bootstrap-vue/src/index.scss';

    @import './assets/_bootswatch.scss';

    /*General style overrides and custom classes*/
    body {
        margin: 0;
    }

    /*.my-widget {
        color: var(--danger);
    }*/
</style>