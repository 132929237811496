<template>
    <div>
        <b-container>
            <b-row>
                <b-col cols="0" md="2" lg="3"></b-col>
                <b-col cols="12" md="8" lg="6">
                    <b-tabs v-model="activeTab" content-class="mt-3" align="center" small>
                        <b-tab title="Storages" title-link-class="px-2">
                            <b-col class="mb-2">
                                <b-row class="d-flex" align-h="between">
                                    <resource-filter />
                                    <b-button class="mb-3 mr-4" variant="primary" size="sm" @click="newStorage=!newStorage">New storage</b-button>
                                </b-row>
                                <b-row align-h="center">
                                    <b-table class="table table-condensed" ref="storageTable" small selectable select-mode="single" sticky-header hover :items="storagesList" primary-key="placeId" :fields="storagesFields" @row-selected="onStorageRowSelected"></b-table>
                                </b-row>
                            </b-col>
                        </b-tab>
                        <b-tab title="Sources" title-link-class="px-2">
                            <b-col class="mb-2" align-self="center">
                                <b-row class="d-flex" align-h="start">
                                    <resource-filter />
                                </b-row>
                                <b-table class="table table-condensed" ref="sourcesTable" small selectable select-mode="single" sticky-header hover :items="sourcesList" primary-key="sensorId" :fields="sourcesFields" @row-selected="onSourceRowSelected"></b-table>
                            </b-col>
                        </b-tab>
                        <b-tab title="Metrics" title-link-class="px-2">
                            <b-col class="mb-2" align-self="center">
                                <b-row class="d-flex" align-h="end">
                                    <b-button class="mb-3 mr-4" variant="primary" size="sm" @click="newMetric=!newMetric">New metric</b-button>
                                </b-row>
                                <b-table class="table table-condensed" ref="metricsTable" small selectable select-mode="single" sticky-header hover :items="metricsList" primary-key="metricId" :fields="metricsFields" @row-selected="onMetricsRowSelected"></b-table>
                            </b-col>
                        </b-tab>
                        <b-tab title="Collectors" title-link-class="px-2">
                            <b-col class="mb-2" align-self="center">
                                <b-row class="d-flex" align-h="end">
                                    <b-button class="mb-3 mr-4" variant="primary" size="sm" @click="newCollector=!newCollector">New collector</b-button>
                                </b-row>
                                <b-table class="table table-condensed" ref="collectorsTable" small selectable select-mode="single" sticky-header hover :items="collectorsList" primary-key="collectorId" :fields="collectorsFields" @row-selected="onCollectorsRowSelected"></b-table>
                            </b-col>
                        </b-tab>
                        <b-tab title="Alerts" :title-link-class="alertClass">
                            <b-col class="mb-2" align-self="center">
                                <b-row class="d-flex" align-h="end">
                                    <b-button class="mb-3 mr-4" variant="primary" size="sm" @click="newAlert=!newAlert">New alert</b-button>
                                </b-row>
                                <b-table class="table table-condensed" ref="alertsTable" small selectable select-mode="single" sticky-header hover :items="alertsList" primary-key="alertId" :fields="alertsFields" @row-selected="onAlertsRowSelected"></b-table>
                            </b-col>
                        </b-tab>
                    </b-tabs>

                    <b-modal :title="'Edit ' + sourceToEdit.originalId" v-model="editSource" @hide="handleEditSourceEvents">
                        <b-container>
                            <b-row>
                                <b-col cols="3">
                                    <label for="sourceName">Name:</label>
                                </b-col>
                                <b-col cols="7">
                                    <b-form-input size="sm" id="sourceName" type="text" v-model="sourceToEdit.name"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="3">
                                    <label for="sourceOffset">Offset:</label>
                                </b-col>
                                <b-col cols="5">
                                    <b-form-input size="sm" id="sourceOffset" type="number" v-model="sourceToEdit.offset"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="3">
                                    <label for="sourceStorage">Storage:</label>
                                </b-col>
                                <b-col cols="7">
                                    <b-form-input size="sm" id="sourceStorage" type="text" v-model="selectedSourceStorage" readonly></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row v-if="!sourceToEdit.used" class="mt-2">
                                <b-col>
                                    <b-alert show variant="info">
                                        Note: To assign this source to a storage, create a new storage or edit an existing one.
                                    </b-alert>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="3">
                                    <label for="sourceCollector">Collector:</label>
                                </b-col>
                                <b-col cols="7">
                                    <b-form-input size="sm" id="sourceCollector" type="text" v-model="changedCollectors.find(x => x.loggerId == sourceToEdit.loggerId).loggerName" readonly></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-checkbox v-model="showDetails"
                                                     name="showSourceDetails">
                                        Show details
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <b-row v-if="showDetails">
                                <b-col>
                                    <b-form-textarea id="textarea-plaintext" plaintext :value="JSON.stringify(changedSources.find(x => x.sensorId==selectedSource), null, 2)"></b-form-textarea>
                                </b-col>
                            </b-row>
                            <!--<b-row v-if="!sourceToEdit.used" class="mt-2">
                                <b-col>
                                    <b-form-checkbox v-model="sourceToEdit.remove"
                                                     name="checkbox-deleteSource">
                                        Delete this source
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                            <b-row v-if="!sourceToEdit.used">
                                <b-col>
                                    <b-alert show variant="info">
                                        Note: Deleted source will reappear if it's still reported by a collector.
                                    </b-alert>
                                </b-col>
                            </b-row>-->
                        </b-container>
                        <template #modal-footer="{ ok, cancel, hide }">
                            <!-- Button with custom close trigger value -->
                            <b-button v-if="!sourceToEdit.used" variant="danger" size="sm" class="mr-auto" @click="hide('delete')">
                                Delete
                            </b-button>
                            <!-- Emulate built in modal footer ok and cancel button actions -->
                            <b-button variant="secondary" size="sm" @click="cancel()">
                                Cancel
                            </b-button>
                            <b-button variant="primary" size="sm" @click="ok()">
                                Ok
                            </b-button>
                        </template>
                    </b-modal>
                    <b-modal :title="'Edit ' + storageToEdit.placeName" v-model="editStorage" @hide="handleEditStorageEvents">
                        <b-row>
                            <b-col cols="2" align-h="end">
                                <label for="storageName">Name:</label>
                            </b-col>
                            <b-col cols="8">
                                <b-form-input size="sm" id="storageName" type="text" v-model="storageToEdit.placeName"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="2">
                                <label for="storageSource">Source:</label>
                            </b-col>
                            <b-col cols="8">
                                <b-form-select class="my-auto" size="sm" id="storageSource" type="text" :options="notUsedSources" @change="storageSourceChange" v-model="selectedStorageSource" :select-size="1"></b-form-select>
                            </b-col>
                            <b-col cols="1">
                                <resource-filter />
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="2">
                                <label for="storageMetric">Metric:</label>
                            </b-col>
                            <b-col cols="8">
                                <b-form-select class="my-auto" size="sm" id="storageMetric" type="text" :options="metricsOptions" @change="storageMetricChange" v-model="selectedStorageMetric" :select-size="1"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="2">
                                <label>labels:</label>
                            </b-col>
                            <b-col cols="8">
                                <b-form-tags v-model="storageToEdit.labels" no-outer-focus class="mb-2">
                                    <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                                        <b-input-group class="mb-2">
                                            <b-form-input size="sm" variant="secondary" v-bind="inputAttrs"
                                                          v-on="inputHandlers"
                                                          placeholder="New tag - Press enter to add"
                                                          class="form-control"></b-form-input>
                                            <b-input-group-append>
                                                <b-button size="sm" @click="addTag()" variant="secondary">Add</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div class="d-inline-block" style="font-size: 1.0rem;">
                                            <b-form-tag v-for="tag in tags"
                                                        @remove="removeTag(tag)"
                                                        :key="tag"
                                                        :title="tag"
                                                        variant="info"
                                                        class="mr-1">{{ tag }}</b-form-tag>
                                        </div>
                                    </template>
                                </b-form-tags>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col>
                                <b-form-checkbox v-model="showDetails"
                                                 name="showStorageDetails">
                                    Show details
                                </b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox class="float-right" v-model="storageToEdit.placePaused"
                                                 name="checkbox-pauseStorage">
                                    Pause this storage
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row v-if="showDetails">
                            <b-col>
                                <b-form-textarea id="textarea-plaintext" plaintext :value="JSON.stringify(changedStorages.find(x => x.placeId==selectedStorage), null, 2)"></b-form-textarea>
                            </b-col>
                        </b-row>
                        <template #modal-footer="{ ok, cancel, hide }">
                            <!-- Button with custom close trigger value -->
                            <b-button v-if="!newStorage" variant="danger" size="sm" class="mr-auto" @click="hide('delete')">
                                Delete
                            </b-button>
                            <!-- Emulate built in modal footer ok and cancel button actions -->
                            <b-button variant="secondary" size="sm" @click="cancel()">
                                Cancel
                            </b-button>
                            <b-button variant="primary" size="sm" @click="ok()">
                                Ok
                            </b-button>
                        </template>
                    </b-modal>
                    <b-modal :title="'Edit ' + metricToEdit.name" v-model="editMetric" @hide="handleEditMetricEvents">
                        <b-row>
                            <b-col cols="3" align-h="end">
                                <label for="metricName">Name:</label>
                            </b-col>
                            <b-col cols="5">
                                <b-form-input size="sm" id="metricName" type="text" v-model="metricToEdit.name"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3" align-h="end">
                                <label for="metricUnit">Unit:</label>
                            </b-col>
                            <b-col cols="5">
                                <b-form-input size="sm" id="metricUnit" type="text" v-model="metricToEdit.unit"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-checkbox v-model="showDetails"
                                                 name="showMetricDetails">
                                    Show details
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row v-if="showDetails">
                            <b-col>
                                <b-form-textarea id="textarea-plaintext" plaintext :value="JSON.stringify(changedMetrics.find(x => x.id==selectedMetric), null, 2)"></b-form-textarea>
                            </b-col>
                        </b-row>
                        <template #modal-footer="{ ok, cancel, hide }">
                            <!-- Button with custom close trigger value -->
                            <b-button v-if="!newMetric" variant="danger" size="sm" class="mr-auto" @click="hide('delete')">
                                Delete
                            </b-button>
                            <!-- Emulate built in modal footer ok and cancel button actions -->
                            <b-button variant="secondary" size="sm" @click="cancel()">
                                Cancel
                            </b-button>
                            <b-button variant="primary" size="sm" @click="ok()">
                                Ok
                            </b-button>
                        </template>
                    </b-modal>
                    <b-modal :title="'Edit ' + collectorToEdit.loggerName" v-model="editCollector" @hide="handleEditCollectorEvents">
                        <b-row>
                            <b-col cols="3" align-h="end">
                                <label for="collectorName">Name:</label>
                            </b-col>
                            <b-col cols="5">
                                <b-form-input size="sm" id="collectorName" type="text" v-model="collectorToEdit.loggerName"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="3">
                                <label for="timezone">Timezone:</label>
                            </b-col>
                            <b-col cols="8">
                                <b-form-select class="my-auto" size="sm" id="timezone" type="text" :options="timezonesOptions" @change="timezoneChange" v-model="selectedTimezone" :select-size="1"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                            </b-col>
                            <b-col cols="8">
                                <b-alert show class="alert alert-condensed" variant="warning">The timezones doesn't consider daylight saving time!<br />Select time zonebased on collector location.</b-alert>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="3" align-h="end">
                                <label for="shortId">ShortId:</label>
                            </b-col>
                            <b-col cols="5">
                                <b-form-input disabled size="sm" id="shortId" type="text" v-model="collectorToEdit.shortId"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="3" align-h="end">
                                <label for="collectorId">Id:</label>
                            </b-col>
                            <b-col cols="8">
                                <b-form-input disabled size="sm" id="collectorId" type="text" v-model="collectorToEdit.loggerId"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2" v-if="unConfiguredCol && !newCollector">
                            <b-col cols="3">
                            </b-col>
                            <b-col cols="8">
                                <b-alert show class="alert alert-condensed" variant="warning">It looks like this collector is not activated!<br />Click activate button to activate a new collector<br />device or delete if it's not going to be used.</b-alert>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-checkbox v-model="showDetails"
                                                 name="showCollectorDetails">
                                    Show details
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row v-if="showDetails">
                            <b-col>
                                <b-form-textarea id="textarea-plaintext" plaintext :value="JSON.stringify(changedCollectors.find(x => x.loggerId==selectedCollector), null, 2)"></b-form-textarea>
                            </b-col>
                        </b-row>
                        <template #modal-footer="{ ok, cancel, hide }">
                            <!-- Button with custom close trigger value -->
                            <b-button size="sm" v-if="!newCollector" variant="danger" class="mr-auto" @click="hide('delete')">
                                Delete
                            </b-button>
                            <b-button size="sm" v-if="unConfiguredCol && !newCollector" variant="secondary" @click="hide('configure')">
                                Activate
                            </b-button>
                            <!-- Emulate built in modal footer ok and cancel button actions -->
                            <b-button size="sm" variant="secondary" @click="cancel()">
                                Cancel
                            </b-button>
                            <b-button size="sm" variant="primary" @click="ok()">
                                Ok
                            </b-button>
                        </template>
                    </b-modal>
                    <b-modal :title="'Edit ' + alertStorageName" v-model="editAlert" @hide="handleEditAlertEvents">
                        <b-row>
                            <b-col cols="4">
                                <label for="storage_name">Storage:</label>
                            </b-col>
                            <b-col cols="6">
                                <b-form-select class="my-auto" size="sm" id="storage_name" type="text" :options="storagesOptions" @change="storageAlertChange" v-model="selectedAlertStorage" :select-size="1"></b-form-select>
                            </b-col>
                            <b-col cols="2">
                                <resource-filter />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4" align-h="end">
                                <label for="alertStatus">Status:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-input size="sm" id="alertStatus" :options="alertStatusOptions" type="text" v-model="alertToEdit.alertStatus" readonly></b-form-input>
                            </b-col>
                            <b-col cols="4">
                                <b-button class="my-1" variant="secondary" size="sm"
                                          @click="alertToEdit.alertStatus == 'disabled' ? alertToEdit.alertStatus = 'enabled' : alertToEdit.alertStatus = 'disabled'">{{alertToEdit.alertStatus == 'disabled' ? 'enable' : 'disable'}}</b-button>
                            </b-col>
                        </b-row>
                        <b-row v-if="alertToEdit.alertStatus == 'alert'">
                            <b-col cols="4" align-h="end">
                            </b-col>
                            <b-col cols="8" align-h="end">
                                <P>Alert @: {{new Date(alertToEdit.timeStatusChange).toLocaleString()}}</P>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4" align-h="end">
                                <label for="alertType">Type:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-select size="sm" id="alertType" :options="alertTypeOptions" type="text" v-model="alertToEdit.type"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row v-if="alertToEdit.type == 'threshold'">
                            <b-col cols="4" align-h="end">
                                <label for="thresholdType">Value @:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-select size="sm" id="thresholdType" :options="thresholdTypeOptions" type="text" v-model="alertToEdit.thresholdType"></b-form-select>
                            </b-col>
                            <b-col cols="4">
                                <b-form-input size="sm" id="thresholdValue" type="number" v-model="alertToEdit.thresholdValue"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row v-if="alertToEdit.type == 'time'">
                            <b-col cols="4" align-h="end">
                                <label for="timePeriod">Time period:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-input size="sm" id="timePeriod" type="number" v-model="timePeriod"></b-form-input>
                            </b-col>
                            <b-col cols="4">
                                <b-form-select size="sm" id="timePeriodUnit" :options="timePeriodUnitOptions" type="text" v-model="timePeriodUnit"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4" align-h="end">
                                <label for="repeatPeriod">Repeat period:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-input size="sm" id="repeatPeriod" type="number" v-model="repeatPeriod"></b-form-input>
                            </b-col>
                            <b-col cols="4">
                                <b-form-select size="sm" id="repeatPeriodUnit" :options="repeatPeriodUnitOptions" type="text" v-model="repeatPeriodUnit"></b-form-select>
                            </b-col>
                        </b-row><br />
                        <strong>e-mail:</strong>
                        <b-row v-for="(email, index) in alertToEdit.email" :key="email">
                            <b-col cols="10">
                                <div class="mt-1">{{ email }}</div>
                            </b-col>
                            <b-col cols="2">
                                <b-button class="my-1" variant="secondary" size="sm" @click="removeEmail(index)">-</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="10">
                                <b-form-input size="sm" id="newEmail" type="email" placeholder="Add new email here" v-model="$v.newAlertEmail.$model" :state="validateState()" aria-describedby="email-input1-feedback"></b-form-input>
                                <b-form-invalid-feedback id="email-input1-feedback">Please enter a valid e-mail address.</b-form-invalid-feedback>
                            </b-col>
                            <b-col cols="2">
                                <b-button class="my-1" variant="secondary" size="sm" @click="addEmail">+</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-checkbox v-model="showDetails"
                                                 name="showAlertDetails">
                                    Show details
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row v-if="showDetails">
                            <b-col>
                                <b-form-textarea id="textarea-plaintext" plaintext :value="JSON.stringify(changedAlerts.find(x => x.alertId==selectedAlert), null, 2)"></b-form-textarea>
                            </b-col>
                        </b-row>
                        <template #modal-footer="{ ok, cancel, hide }">
                            <!-- Button with custom close trigger value -->
                            <b-button v-if="!newAlert" variant="danger" size="sm" class="mr-auto" @click="hide('delete')">
                                Delete
                            </b-button>
                            <!-- Emulate built in modal footer ok and cancel button actions -->
                            <b-button variant="secondary" size="sm" @click="cancel()">
                                Cancel
                            </b-button>
                            <b-button variant="primary" size="sm" @click="ok()">
                                Ok
                            </b-button>
                        </template>
                    </b-modal>
                    <b-modal id="autoConf" v-model="confCollector" title="Auto configure new collector" @shown="autoConfOpen" @hide="handleAutoConfEvents">
                        <b-row>
                            <b-col cols="12">
                                <p>
                                    The new collector must be running in your local network and this l4a client session must be running in a browser
                                    connected via the same local network.<br /><br />
                                    If the new collector can not be located you will get an error message in your browser about it. If that happens, just navigate back to the Logg4all application.
                                </p>
                            </b-col>
                        </b-row>

                        <b-row class="mt-2">
                            <b-col cols="3" align-h="end">
                                <label for="collectorId">Id:</label>
                            </b-col>
                            <b-col cols="8">
                                <b-form-input disabled size="sm" id="collectorId" type="text" v-model="collectorToEdit.loggerId"></b-form-input>
                            </b-col>
                        </b-row>
                        <template #modal-footer="{ ok, cancel }">
                            <!-- Emulate built in modal footer ok and cancel button actions -->
                            <b-button variant="secondary" @click="cancel()">
                                Cancel
                            </b-button>
                            <b-button variant="primary" @click="ok()">
                                Ok
                            </b-button>
                        </template>
                    </b-modal>
                </b-col>
                <b-col cols="0" md="2" lg="3"></b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import filter from '@/components/Settings/filter.vue'
    import { isInSelectedCollectors, isInSelectedTypes } from '@/assets/l4a_jsAssets'
    import { uuid } from 'vue-uuid';
    import timezones from 'timezones-list';
    import Amplify from 'aws-amplify';
    import { required, email } from 'vuelidate/lib/validators';

    export default {
        name: 'settings',
        data() {
            return {
                selectedSource: '',
                selectedStorage: '',
                selectedMetric: '',
                selectedCollector: '',
                selectedAlert: '',
                sourceToEdit: {},
                storageToEdit: {},
                metricToEdit: {},
                alertToEdit: {},
                collectorToEdit: {},
                storagesFields: [
                    {
                        key: 'storage_name',
                        sortable: true
                    },
                    {
                        key: 'source_assigned',
                        sortable: true
                    },
                    {
                        key: 'paused',
                        sortable: true
                    }],
                sourcesFields: [
                    {
                        key: 'source_name',
                        sortable: true
                    },
                    {
                        key: 'originalId',
                        sortable: true
                    },
                    {
                        key: 'used',
                        sortable: true
                    }],
                metricsFields: [
                    {
                        key: 'metric_name',
                        sortable: true
                    },
                    {
                        key: 'unit',
                        sortable: true
                    }],
                collectorsFields: [
                    {
                        key: 'collector_name',
                        sortable: true
                    }],
                alertsFields: [
                    {
                        key: 'storage_name',
                        sortable: true
                    },
                    {
                        key: 'alertStatus',
                        sortable: true
                    },
                    {
                        key: 'alertType',
                        sortable: true
                    }],
                storageSelected: [],
                selectedSourceStorage: '',
                selectedStorageSource: '',
                selectedStorageOldSource: '',
                selectedStorageMetric: '',
                selectedAlertStorage: '',
                selectedTimezone: '',
                selectedCollectors: [],
                selectedTypes: [],
                selectedLabels: [],
                editSource: false,
                editStorage: false,
                editMetric: false,
                editCollector: false,
                editAlert: false,
                confCollector: false,
                activeTab: 0,
                newStorage: false,
                newMetric: false,
                newCollector: false,
                newAlert: false,
                changedSources: [],
                changedStorages: [],
                changedMetrics: [],
                changedCollectors: [],
                changedAlerts: [],
                oldFilter: {},
                showOnlyUnusedSources: false,
                timezones: timezones,
                progressText: 'Searching for new Collector...',
                newCollectorUrl: 'http://newl4acollector.local:3000',
                sourceUsageChanged: false,
                newAlertEmail: null,
                alertTypeOptions: [
                    { value: 'time', text: 'Time' },
                    { value: 'threshold', text: 'Threshold' }
                ],
                alertStatusOptions: [
                    { value: 'enabled', text: 'Enabled' },
                    { value: 'disabled', text: 'Disabled' }
                ],
                thresholdTypeOptions: [
                    { value: '>', text: '>' },
                    { value: '<', text: '<' }
                ],
                timePeriodUnitOptions: [
                    { value: 3600000, text: 'hours' },
                    { value: 86400000, text: 'days' }
                ],
                repeatPeriodUnitOptions: [
                    { value: 3600000, text: 'hours' },
                    { value: 86400000, text: 'days' }
                ],
                timePeriodUnit: 3600000,
                repeatPeriodUnit: 86400000,
                timePeriod: 6,
                repeatPeriod: 1,
                showDetails: false
            }
        },
        validations: {
            newAlertEmail: {
                email,
                required
            }
        },
        computed: {
            filterActive() {
                return this.rfilter.selectedCollectors.length > 0 || this.rfilter.selectedTypes.length > 0 || this.rfilter.selectedLabels.length > 0 || this.rfilter.unusedSources
            },
            rfilter() {
                return this.$store.getters.filter
            },
            storagesList() {
                let s = [];
                let availableStorages = this.changedStorages.filter(isInSelectedCollectors, {
                    selectedCollectors: this.rfilter.selectedCollectors,
                    changedCollectors: this.changedCollectors,
                    changedSources: this.changedSources
                }).filter(isInSelectedTypes, {
                    selectedTypes: this.rfilter.selectedTypes,
                    changedSources: this.changedSources
                })
                for (let storage of availableStorages) {
                    // Check if the storage include any of the selected labels.
                    // If no label is selected all storages shall be selected
                    let sourceAssigned
                    if (this.changedSources.findIndex(x => x.placeId === storage.placeId) > -1) {
                        sourceAssigned = this.changedSources.find(x => x.placeId === storage.placeId).originalId
                    } else {
                        sourceAssigned = 'none'
                    }
                    if (this.rfilter.selectedLabels.length > 0) {
                        if (typeof storage.labels !== 'undefined') {
                            for (let label of storage.labels) {
                                if (this.rfilter.selectedLabels.indexOf(label) > -1) s.push({ storage_name: storage.placeName, source_assigned: sourceAssigned, paused: storage.placePaused, placeId: storage.placeId })
                            }
                        }
                    } else {
                        s.push({ storage_name: storage.placeName, source_assigned: sourceAssigned, paused: storage.placePaused, placeId: storage.placeId })
                    }
                }
                return s.sort(function (a, b) {
                    var nameA = a.storage_name.toLowerCase(), nameB = b.storage_name.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
            },
            sourcesList() {
                let s1 = []
                for (let source1 of this.changedSources) {
                    if (this.rfilter.unusedSources && !source1.used || !this.rfilter.unusedSources) {
                        s1.push(source1)
                    }
                }
                let s2 = []
                for (let source2 of s1) {
                    if (this.rfilter.selectedCollectors.length != 0) {
                        let collectorName = this.changedCollectors.find(x => x.loggerId === source2.loggerId).loggerName
                        if (this.changedCollectors.findIndex(x => x.loggerId === source2.loggerId) > -1 &&
                            this.rfilter.selectedCollectors.includes(collectorName)) {
                            s2.push(source2)
                        }
                    } else {
                        s2.push(source2)
                    }

                }
                let s3 = []
                for (let source3 of s2) {
                    if (this.rfilter.selectedTypes.length != 0) {
                        let sourceType = source3.originalId.split("_")[0]
                        if (this.changedSources.originalId ||
                            this.rfilter.selectedTypes.includes(sourceType)) {
                            s3.push(source3)
                        }
                    } else {
                        s3.push(source3)
                    }
                }
                s3.sort(function (a, b) {
                    var nameA = a.originalId.toLowerCase(), nameB = b.originalId.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
                let filteredSources = []
                for (let source of s3) {
                    filteredSources.push({ source_name: source.name, originalId: source.originalId, used: source.used, sensorId: source.sensorId })
                }
                return filteredSources
            },
            metricsList() {
                let m = []
                for (let metric of this.changedMetrics) {
                    m.push({ metric_name: metric.name, unit: metric.unit, metricId: metric.id })
                }
                return m.sort(function (a, b) {
                    var nameA = a.metric_name.toLowerCase(), nameB = b.metric_name.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
            },
            collectorsList() {
                let c = []
                for (let collector of this.changedCollectors) {
                    c.push({ collector_name: collector.loggerName, collectorId: collector.loggerId })
                }
                return c.sort(function (a, b) {
                    var nameA = a.collector_name.toLowerCase(), nameB = b.collector_name.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
            },
            alertsList() {
                let a = []
                for (let alert of this.changedAlerts) {
                    if (this.changedStorages.findIndex(x => x.placeId == alert.storageId) > -1) {
                        a.push({ storage_name: this.changedStorages.find(x => x.placeId == alert.storageId).placeName, alertStatus: alert.alertStatus, alertType: alert.type, alertId: alert.alertId, _cellVariants: { alertStatus: alert.alertStatus == 'alert' ? 'danger' : '' } })
                    }

                }
                return a.sort(function (a, b) {
                    var nameA = a.storage_name.toLowerCase(), nameB = b.storage_name.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
            },
            unConfiguredCol() {
                if (typeof this.collectorToEdit.loggerId !== 'undefined' && typeof this.collectorToEdit.certId !== 'undefined' && this.collectorToEdit.certId == 'tbd') {
                    return true
                } else {
                    return false
                }
            },
            timezonesOptions() {
                let options = []
                for (let timezone of this.timezones) {
                    options.push({ value: timezone.tzCode, text: timezone.utc + " " + timezone.tzCode })
                }

                return options
            },
            notUsedSources() {
                let storage = this.storageToEdit
                let sourcesNotUsed = []
                if (typeof storage != 'undefined' && typeof storage.placeId != 'undefined' && storage.placeId != 'tbd' && storage.placeId != '-' && this.changedSources.findIndex(x => x.placeId === storage.placeId) > -1) {
                    sourcesNotUsed = [{ text: this.changedSources.find(x => x.placeId === storage.placeId).originalId, value: this.changedSources.find(x => x.placeId === storage.placeId).sensorId },
                    { text: 'none', value: 'tbd' }]
                } else {
                    sourcesNotUsed = [{ text: 'none', value: 'tbd' }]
                }
                for (let source of this.sourcesList) {
                    if (!source.used) {
                        sourcesNotUsed.push({ text: source.originalId, value: source.sensorId })
                    }
                }
                return sourcesNotUsed
            },
            metricsOptions() {
                let options = []
                for (let metric of this.changedMetrics) {
                    options.push({ text: metric.name, value: metric.id })
                }
                return options
            },
            storagesOptions() {
                let options = []
                for (let storage of this.storagesList) {
                    options.push({ text: storage.storage_name, value: storage.placeId })
                }
                return options
            },
            alertStorageName() {
                if (this.changedStorages.findIndex(x => x.placeId == this.alertToEdit.storageId) > -1) {
                    return this.changedStorages.find(x => x.placeId == this.alertToEdit.storageId).placeName
                } else {
                    return ''
                }

            },
            localWsOpen() {
                return this.$store.getters.webSocketOpen && this.$store.getters.wsTarget === 'localCollector'
            },
            alertClass() {
                return this.changedAlerts.findIndex(x => x.alertStatus == 'alert') > -1 ? 'px-2 text-danger' : 'px-2'
            },

        },
        methods: {
            handleEditSourceEvents(event) {
                switch (event.trigger) {
                    case 'ok': {
                        this.sourceToEdit.offset = parseFloat(this.sourceToEdit.offset)
                        if (this.sourceToEdit.name == '') this.sourceToEdit.name = 'tbd'
                        let sourceIndex = this.changedSources.findIndex(x => x.sensorId == this.sourceToEdit.sensorId)
                        if (sourceIndex > -1) {
                            this.changedSources[sourceIndex] = this.sourceToEdit
                        }
                        this.$store.commit('updateSourceConfig', this.changedSources)
                        this.changedSources = this._.cloneDeep(this.$store.getters.sources) // Had to do this to get the information in the table to update
                        this.$store.dispatch('updateUserConfig')
                        break;
                    }
                    case 'delete': {
                        // Ask if user is sure it should be deleted
                        this.$bvModal.msgBoxConfirm("Are you sure you want to delete Source: " + this.sourceToEdit.originalId + " ? Note: Deleted source will reappear if it's still reported by a collector.")
                            .then(value => {
                                if (value) {
                                    let sourceIndex = this.changedSources.findIndex(x => x.sensorId == this.sourceToEdit.sensorId)
                                    if (sourceIndex > -1) {
                                        this.sourceToEdit.remove = true
                                        this.changedSources[sourceIndex] = this.sourceToEdit
                                        this.$store.commit('updateSourceConfig', this._.cloneDeep(this.changedSources))
                                        this.$store.dispatch('updateUserConfig')
                                        this.changedSources.splice(sourceIndex, 1)
                                    }
                                }
                            })
                            .catch(err => {
                                // An error occurred
                                console.log(err)
                            })
                        break;
                    }
                }
            },
            sourceChange() {
                if (this.$store.getters.sources.findIndex(x => x.sensorId == this.selectedSource) > -1) {
                    this.sourceToEdit = this._.cloneDeep(this.$store.getters.sources.find(x => x.sensorId == this.selectedSource))
                } else {
                    this.sourceToEdit = {}
                }
                if (typeof this.sourceToEdit.placeId != 'undefined' && this.sourceToEdit.placeId != 'tbd' && this.changedStorages.findIndex(x => x.placeId === this.sourceToEdit.placeId) > -1) {
                    this.selectedSourceStorage = this.changedStorages.find(x => x.placeId === this.sourceToEdit.placeId).placeName
                } else {
                    this.selectedSourceStorage = 'tbd'
                }
            },
            handleEditStorageEvents(event) {
                switch (event.trigger) {
                    case 'ok': {
                        let storageIndex = this.changedStorages.findIndex(x => x.placeId == this.storageToEdit.placeId)
                        if (storageIndex > -1) {
                            this.changedStorages[storageIndex] = this.storageToEdit
                        } else {
                            this.changedStorages.push(this.storageToEdit)  // This happens if a new Storage has been defined.
                        }
                        this.$store.commit('updateStorageConfig', this.changedStorages)
                        this.$store.commit('updateSourceConfig', this.changedSources)
                        this.$store.commit('updateSourcesChanged', this.sourceUsageChanged)
                        this.$store.dispatch('updateUserConfig')
                        this.newStorage = false
                        this.sourceUsageChanged = false;
                        break;
                    }
                    case 'delete': {
                        // Check that there is no Source using the Storage.
                        // Ask if user is sure it should be deleted
                        if (this.changedSources.findIndex(x => x.placeId === this.selectedStorage) > -1) {
                            this.$bvModal.msgBoxOk("This Storage can't be deleted. It's used by source: " + this.changedSources.find(x => x.placeId === this.selectedStorage).originalId)
                        } else {
                            this.$bvModal.msgBoxConfirm('Are you sure you want to delete Storage: ' + this.storageToEdit.placeName + ' ?')
                                .then(value => {
                                    if (value) {
                                        let storageIndex = this.changedStorages.findIndex(x => x.placeId == this.selectedStorage)
                                        this.changedStorages.splice(storageIndex, 1)
                                        this.$store.commit('updateStorageConfig', this.changedStorages)
                                        this.$store.dispatch('updateUserConfig')
                                        if (this.storagesList.length > 0) {
                                            this.selectedStorage = this.storagesList[0].placeId
                                        } else {
                                            this.selectedStorage = ''
                                        }
                                    }
                                })
                                .catch(err => {
                                    // An error occurred
                                    console.log(err)
                                })
                        }
                        break;
                    }
                }

            },
            storageChange() {
                if (this.selectedStorage == "new") {
                    this.storageToEdit = {
                        placeId: uuid.v4(),
                        userId: this.$store.getters.user,
                        metricId: '',
                        placeName: 'tbd',
                        previousSource: 'none',
                        placePaused: false,
                        del: false
                    }
                    this.selectedStorage = this.storageToEdit.placeId
                } else {
                    this.storageToEdit = this.changedStorages.find(x => x.placeId == this.selectedStorage)
                }
                if (typeof this.storageToEdit.labels === 'undefined') this.storageToEdit.labels = []
                this.selectedStorageMetric = this.storageToEdit.metricId
                if (this.changedSources.findIndex(x => x.placeId === this.storageToEdit.placeId) > -1) {
                    this.selectedStorageOldSource = this.changedSources.find(x => x.placeId === this.storageToEdit.placeId).sensorId
                    this.selectedStorageSource = this.changedSources.find(x => x.placeId === this.storageToEdit.placeId).sensorId
                } else {
                    this.selectedStorageOldSource = 'undefined'
                    this.selectedStorageSource = 'tbd'
                }
            },
            handleEditMetricEvents(event) {
                this.newMetric = false
                switch (event.trigger) {
                    case 'ok': {
                        let metricIndex = this.changedMetrics.findIndex(x => x.id == this.metricToEdit.id)
                        if (metricIndex > -1) {
                            this.changedMetrics[metricIndex] = this.metricToEdit
                        } else {
                            this.changedMetrics.push(this.metricToEdit)  // This happens if a new Metric has been defined.
                        }
                        this.$store.commit('updateMetricsConfig', this.changedMetrics)
                        this.$store.dispatch('updateUserConfig')
                        break;
                    }
                    case 'delete': {
                        // Check that there is no Storage using the Metric.
                        if (this.changedStorages.findIndex(x => x.metricId === this.selectedMetric) > -1) {
                            // Inform that it can't be deleted as long as there are storage(s) using the metric.
                            this.$bvModal.msgBoxOk("Metric can't be deleted if it's used by a Storage")
                        } else {
                            // Ask if user is sure it should be deleted
                            this.$bvModal.msgBoxConfirm('Are you sure you want to delete Metric: ' + this.metricToEdit.name + ' ?')
                                .then(value => {
                                    if (value) {
                                        let metricIndex = this.changedMetrics.findIndex(x => x.id == this.metricToEdit.id)
                                        this.changedMetrics.splice(metricIndex, 1)
                                        this.$store.commit('updateMetricsConfig', this.changedMetrics)
                                        this.$store.dispatch('updateUserConfig')
                                        if (this.metricsOptions.length > 0) {
                                            this.selectedMetric = this.metricsOptions[0].value
                                        } else {
                                            this.selectedMetric = ''
                                        }
                                    }
                                })
                                .catch(err => {
                                    // An error occurred
                                    console.log(err)
                                })
                        }
                        break;
                    }
                }
            },
            metricChange() {
                if (this.selectedMetric == "new") {
                    this.metricToEdit = {
                        id: uuid.v4(),
                        name: 'tbd',
                        unit: 'tbd'
                    }
                    this.selectedMetric = this.metricToEdit.id
                } else {
                    this.metricToEdit = this.changedMetrics.find(x => x.id == this.selectedMetric)
                }
            },
            handleEditCollectorEvents(event) {
                this.newCollector = false
                switch (event.trigger) {
                    case 'ok': {
                        let collectorIndex = this.changedCollectors.findIndex(x => x.loggerId == this.collectorToEdit.loggerId)
                        if (collectorIndex > -1) {
                            this.changedCollectors[collectorIndex] = this.collectorToEdit
                        } else {
                            this.changedCollectors.push(this.collectorToEdit)
                            this.confCollector = true; // This happens if a new Collector has been defined.
                        }
                        this.$store.commit('updateCollectorsConfig', this.changedCollectors)
                        this.$store.dispatch('updateUserConfig')
                        break;
                    }
                    case 'configure': {
                        this.confCollector = true;
                        break;
                    }
                    case 'delete': {
                        // Check that there is no Storage using Sources from this Collector.
                        let preventDelete = false;
                        for (let source of this.changedSources) {
                            if (source.loggerId === this.selectedCollector && source.used) preventDelete = true;
                        }
                        if (preventDelete) {
                            // Inform that it can't be deleted as long as there are storage(s) using Sources from this Collector.
                            this.$bvModal.msgBoxOk("Collector can't be deleted since it's reporting at least one Source used by a Storage")
                        } else {
                            // Ask if user is sure it should be deleted
                            this.$bvModal.msgBoxConfirm('Are you sure you want to delete Collector: ' + this.collectorToEdit.loggerName + ' ?')
                                .then(value => {
                                    if (value) {
                                        let collectorIndex = this.changedCollectors.findIndex(x => x.loggerId == this.collectorToEdit.loggerId)
                                        this.changedCollectors.splice(collectorIndex, 1)
                                        this.$store.commit('updateCollectorsConfig', this.changedCollectors)
                                        this.$store.dispatch('updateUserConfig')
                                        //if (this.collectorsOptions.length > 0) {
                                        //    this.selectedCollector = this.collectorsOptions[0].value
                                        //} else {
                                        //    this.selectedCollector = ''
                                        //}
                                    }
                                })
                                .catch(err => {
                                    // An error occurred
                                    console.log(err)
                                })
                        }
                        break;
                    }
                }
            },
            collectorChange() {
                if (this.selectedCollector == "new") {
                    // Create new collector
                    let nextColNumber = Math.max.apply(Math, this.changedCollectors.map(function (o) { return o.number; })) + 1;
                    let offset = new Date().getTimezoneOffset();
                    var myformat = new Intl.NumberFormat('en-US', {
                        minimumIntegerDigits: 2,
                        minimumFractionDigits: 0
                    });
                    let sign = offset < 0 ? '+' : '-' // note that the getTimeOfset give -120 for GMT+2
                    let offsetString = sign + myformat.format(Math.floor(Math.abs(offset) / 60)) + ':' + myformat.format(Math.abs(offset) % 60)
                    let tz = timezones.find(x => x.utc == offsetString).tzCode
                    this.collectorToEdit = {
                        loggerId: uuid.v4(),
                        loggerName: 'new' + nextColNumber,
                        number: nextColNumber,
                        service: 'local',
                        shortId: 'col' + nextColNumber,
                        timezone: tz,
                        userId: this.$store.getters.user,
                        certId: 'tbd'
                    }

                    this.selectedCollector = this.collectorToEdit.loggerId
                    this.selectedTimezone = this.collectorToEdit.timezone
                } else {
                    this.collectorToEdit = this.changedCollectors.find(x => x.loggerId == this.selectedCollector)
                    this.selectedTimezone = this.collectorToEdit.timezone
                }
            },
            handleEditAlertEvents(event) {
                this.newAlert = false
                switch (event.trigger) {
                    case 'ok': {
                        let alertIndex = this.changedAlerts.findIndex(x => x.alertId == this.alertToEdit.alertId)
                        this.alertToEdit.timePeriod = this.timePeriod * this.timePeriodUnit
                        this.alertToEdit.repeatPeriod = this.repeatPeriod * this.repeatPeriodUnit
                        if (alertIndex > -1) {
                            this.changedAlerts[alertIndex] = this.alertToEdit
                        } else {
                            this.alertToEdit.storageId = this.selectedAlertStorage
                            this.changedAlerts.push(this.alertToEdit) // This happens if a new Alert has been defined.
                        }
                        this.$store.commit('updateAlertsConfig', this.changedAlerts)
                        this.$store.dispatch('updateUserConfig')
                        break;
                    }
                    case 'delete': {

                        // Ask if user is sure it should be deleted
                        this.$bvModal.msgBoxConfirm('Are you sure you want to delete Alert for: ' + this.changedStorages.find(x => x.placeId == this.alertToEdit.storageId).placeName + ' ?')
                            .then(value => {
                                if (value) {
                                    let alertIndex = this.changedAlerts.findIndex(x => x.alertId == this.alertToEdit.alertId)
                                    //this.changedAlerts.splice(alertIndex, 1)
                                    this.changedAlerts[alertIndex].remove = true;
                                    this.$store.commit('updateAlertsConfig', this._.cloneDeep(this.changedAlerts))
                                    this.$store.dispatch('updateUserConfig')
                                    this.changedAlerts.splice(alertIndex, 1)
                                    //if (this.alertsOptions.length > 0) {
                                    //    this.selectedAlert = this.alertOptions[0].value
                                    //} else {
                                    //    this.selectedAlert = ''
                                    //}
                                }
                            })
                            .catch(err => {
                                // An error occurred
                                console.log(err)
                            })

                        break;
                    }
                }
            },
            alertChange() {
                if (this.selectedAlert == "new") {
                    this.selectedAlertStorage = this.storagesList[0].placeId
                    this.alertToEdit = {
                        alertId: uuid.v4(),
                        storageId: 'tbd',
                        latestAlert: 0,
                        email: [
                            this.$store.getters.userAttributes.email
                        ],
                        alertStatus: 'disabled',
                        thresholdValue: 0,
                        nextCheck: 0,
                        timeStatusChange: 0,
                        timePeriod: 3600000,
                        userId: this.$store.getters.user,
                        repeatPeriod: 86400000,
                        thresholdType: '>',
                        type: 'time',
                        new: true
                    }
                    this.selectedAlert = this.alertToEdit.alertId
                } else {
                    this.alertToEdit = this.changedAlerts.find(x => x.alertId == this.selectedAlert)
                    this.selectedAlertStorage = this.alertToEdit.storageId
                }
                this.alertToEdit.timePeriod >= 86400000 ? this.timePeriodUnit = 86400000 : this.timePeriodUnit = 3600000
                this.alertToEdit.repeatPeriod >= 86400000 ? this.repeatPeriodUnit = 86400000 : this.repeatPeriodUnit = 3600000
                this.timePeriod = this.alertToEdit.timePeriod / this.timePeriodUnit
                this.repeatPeriod = this.alertToEdit.repeatPeriod / this.repeatPeriodUnit
            },
            removeEmail(event) {
                console.log('removeEmail event: ' + JSON.stringify(event, null, 2))
                if (event > -1) {
                    this.alertToEdit.email.splice(event, 1);
                }
            },
            validateState() {
                const { $dirty, $error } = this.$v.newAlertEmail;
                return $dirty ? !$error : null;
            },
            addEmail() {
                this.$v.newAlertEmail.$touch();
                if (this.$v.newAlertEmail.$anyError) {
                    return;
                }
                this.alertToEdit.email.push(this.newAlertEmail)
                this.newAlertEmail = null
                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            autoConfOpen() {

            },
            handleAutoConfEvents(event) {
                switch (event.trigger) {
                    case 'ok': {
                        this.$store.commit('updateNewCollector', this.selectedCollector)
                        // Register new collectorId for collector secrets creation in ube
                        Amplify.Auth.currentAuthenticatedUser()
                            .then(async () => {
                                try {
                                    let body = {
                                        collectorId: this.collectorToEdit.loggerId
                                    }
                                    let response = await Amplify.API.post("l4a-cbe", '/dev/createcollectorid', { body: body })
                                    console.log('response: ' + JSON.stringify(response, null, 2))
                                    //this.url = 'http://newl4acollector.local:3000/config?collectorId=' + this.collectorToEdit.loggerId + '&shortId=' + this.collectorToEdit.shortId + '&returnUrl=https%3A%2F%2Fdev.log4all.com%2F%23%2Fsettings'
                                    let url = this.newCollectorUrl + '/config?collectorId=' + this.collectorToEdit.loggerId + '&shortId=' + this.collectorToEdit.shortId + '&returnUrl=' + encodeURIComponent(window.location.href);
                                    window.location.href = url;
                                } catch (err) {
                                    console.log(err)
                                }

                            })
                            .catch(err => console.log(JSON.stringify(err, null, 2)))
                        break;
                    }
                    case 'cancel': {
                        break;
                    }
                }
            },
            //editFilterOk() {
            //    // ToDo
            //},
            //editFilterCancel() {
            //    // ToDo
            //},
            storageSourceChange() {
                // This method change the "old" source to unused and placeId to "tbd". The "new" source shall be used and have placeId of the Storage being edited.
                let oldSourceIndex = this.changedSources.findIndex(x => x.sensorId == this.selectedStorageOldSource)
                if (oldSourceIndex > -1) {
                    this.changedSources[oldSourceIndex].used = false
                    this.changedSources[oldSourceIndex].placeId = 'tbd'
                }
                let newSourceIndex = this.changedSources.findIndex(x => x.sensorId == this.selectedStorageSource)
                if (newSourceIndex > -1) {
                    this.changedSources[newSourceIndex].used = true
                    this.changedSources[newSourceIndex].placeId = this.storageToEdit.placeId
                    this.storageToEdit.previousSource = this.selectedStorageOldSource
                }
                this.selectedStorageOldSource = this.selectedStorageSource
                console.log(this.selectedStorageSource)
                this.sourceUsageChanged = true;
            },
            storageMetricChange() {
                this.storageToEdit.metricId = this.selectedStorageMetric
            },
            storageAlertChange() {
                this.alertToEdit.storageId = this.selectedAlertStorage
            },
            onStorageRowSelected(items) {
                if (items.length > 0) {
                    this.selectedStorage = items[0].placeId
                    this.editStorage = true
                    this.$refs.storageTable.clearSelected()
                }
            },
            onSourceRowSelected(items) {
                if (items.length > 0) {
                    this.selectedSource = items[0].sensorId
                    this.editSource = true
                    this.$refs.sourcesTable.clearSelected()
                }
            },
            onMetricsRowSelected(items) {
                if (items.length > 0) {
                    this.selectedMetric = items[0].metricId
                    this.editMetric = true
                    this.$refs.metricsTable.clearSelected()
                }
            },
            onCollectorsRowSelected(items) {
                if (items.length > 0) {
                    this.selectedCollector = items[0].collectorId
                    this.editCollector = true
                    this.$refs.collectorsTable.clearSelected()
                }
            },
            onAlertsRowSelected(items) {
                if (items.length > 0) {
                    this.selectedAlert = items[0].alertId
                    this.editAlert = true
                    this.$refs.alertsTable.clearSelected()
                }
            },
            timezoneChange() {
                // todo
                this.collectorToEdit.timezone = this.selectedTimezone
            }
        },
        components: {
            'resource-filter': filter
        },
        created() {
            this.$store.dispatch('getUserConfig')
            this.changedSources = this._.cloneDeep(this.$store.getters.sources)
            this.changedStorages = this._.cloneDeep(this.$store.getters.storages)
            this.changedMetrics = this._.cloneDeep(this.$store.getters.metrics)
            this.changedCollectors = this._.cloneDeep(this.$store.getters.collectors)
            this.changedAlerts = this._.cloneDeep(this.$store.getters.alerts)//.filter(x => typeof x.remove == 'undefined' || x.remove == false)
            this.selectedCollector = this.collectorsList[0].collectorId
            this.selectedSource = this.sourcesList[0].sensorId
            this.selectedStorage = this.storagesList[0].placeId
            this.selectedMetric = this.metricsList[0].metricId
            this.selectedAlert = this.alertsList[0].alertId
            this.sourceToEdit = this._.cloneDeep(this.$store.getters.sources.find(x => x.sensorId == this.selectedSource))
            this.storageToEdit = this._.cloneDeep(this.$store.getters.storages.find(x => x.placeId == this.selectedStorage))
            if (typeof this.storageToEdit.labels === 'undefined') this.storageToEdit.labels = []
            if (this.$store.getters.storages.findIndex(x => x.placeId === this.sourceToEdit.placeId) > -1) {
                this.selectedSourceStorage = this.$store.getters.storages.find(x => x.placeId === this.sourceToEdit.placeId).placeName
                this.selectedStorageMetric = this.storageToEdit.metricId
            }
            if (this.$store.getters.sources.findIndex(x => x.placeId === this.storageToEdit.placeId) > -1) {
                this.selectedStorageOldSource = this.$store.getters.sources.find(x => x.placeId === this.storageToEdit.placeId).sensorId
                this.selectedStorageSource = this.$store.getters.sources.find(x => x.placeId === this.storageToEdit.placeId).sensorId

            }
        },
        beforeMount() {
            // Logic to handle the return query parameters from a configured collector
            console.log('route.query.config: ' + this.$route.query.config);
            console.log('route.query.certId: ' + this.$route.query.certId);
            if (typeof this.$route.query.config !== 'undefined') {
                this.activeTab = 3;
                if (this.$route.query.config === 'ok') {
                    let collectorIndex = this.changedCollectors.findIndex(x => x.loggerId == this.$store.getters.newCollector)
                    if (collectorIndex > -1) {
                        this.changedCollectors[collectorIndex].certId = this.$route.query.certId;
                        this.$store.commit('updateCollectorsConfig', this.changedCollectors)
                        this.$store.dispatch('updateUserConfig')
                        this.$bvModal.msgBoxOk('Collector was activated successfully.', {
                            title: 'Confirmation',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true
                        })
                    }
                } else {
                    this.$bvModal.msgBoxOk('Collector activation failed.', {
                        title: 'Error',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'warning',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    })
                }
                // Add code to add the new certId to the created new collector
                let query = Object.assign({}, this.$route.query);
                delete query.config;
                delete query.certId;
                this.$router.replace({ query });

            }
        },
        watch: {
            editStorage: function () {
                if (this.editStorage) {
                    this.changedSources = this._.cloneDeep(this.$store.getters.sources)
                    this.changedStorages = this._.cloneDeep(this.$store.getters.storages)
                    this.changedMetrics = this._.cloneDeep(this.$store.getters.metrics)
                    this.storageChange()
                }
            },
            newStorage: function () {
                if (this.newStorage) {
                    this.selectedStorage = "new"
                    this.editStorage = true
                }
            },
            editSource: function () {
                if (this.editSource) {
                    this.changedSources = this._.cloneDeep(this.$store.getters.sources)
                    this.changedStorages = this._.cloneDeep(this.$store.getters.storages)
                    this.changedMetrics = this._.cloneDeep(this.$store.getters.metrics)
                    this.sourceChange()
                }
            },
            editMetric: function () {
                if (this.editMetric) {
                    this.changedSources = this._.cloneDeep(this.$store.getters.sources)
                    this.changedStorages = this._.cloneDeep(this.$store.getters.storages)
                    this.changedMetrics = this._.cloneDeep(this.$store.getters.metrics)
                    this.metricChange()
                }
            },
            newMetric: function () {
                if (this.newMetric) {
                    this.selectedMetric = "new"
                    this.editMetric = true
                }
            },
            editCollector: function () {
                if (this.editCollector) {
                    this.changedCollectors = this._.cloneDeep(this.$store.getters.collectors)
                    this.collectorChange()
                }
            },
            newCollector: function () {
                if (this.newCollector) {
                    this.selectedCollector = "new"
                    this.editCollector = true
                }
            },
            editAlert: function () {
                if (this.editAlert) {
                    this.changedAlerts = this._.cloneDeep(this.$store.getters.alerts)//.filter(x => typeof x.remove == 'undefined' || x.remove == false)
                    this.alertChange()
                }
            },
            newAlert: function () {
                if (this.newAlert) {
                    this.selectedAlert = "new"
                    this.editAlert = true
                }
            },
            editStorageSelectedTypes: function () {
                this.$store.commit('updateEdiStorageSelectedTypes', this.editStorageSelectedTypes)
            },
            editStorageSelectedCollectors: function () {
                this.$store.commit('updateEditStorageSelectedCollectors', this.editStorageSelectedCollectors)
            },
            localWsOpen: function () {
                if (this.localWsOpen) {
                    this.$socket.sendObj({ type: 'collectorMessage', message: 'startConfig' })
                }
            }
        }
    }
</script>

<style scoped>
    .table-condensed {
        font-size: 12px;
    }

    .alert-condensed {
        font-size: 10px;
    }

    .red {
        color: red;
    }
</style>