<template>
    <div>
        <h1>Development test page</h1>
        <b-form>
            <b-button ref="bt1" class="mt-2" v-on:click="testAddDb" variant="primary">Add dashboard</b-button>
            <b-form-select v-model="selected" :options="options"></b-form-select>
            <b-button class="mt-2" v-on:click="testRmvDb" variant="primary">Remove dashboard</b-button>
            <b-button class="mt-2" v-on:click="testGetData" variant="primary">Test getData</b-button>
        </b-form>
        <p>{{selected}}</p>
        <br />
        <p>w: {{windowWidth}}</p>
        <p>h: {{windowHeight}}</p>
        <p>btw: {{btWidth}}</p>
        <p>bth: {{btHeight}}</p>
        <apexchart type="rangeArea" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

</template>

<script>
    import { getData } from '@/assets/l4a_jsAssets.js';
    import VueApexCharts from 'vue-apexcharts'
    
    export default {
        name: 'protected',
        data() {
            return {
                selected: null,
                windowHeight: 0,
                windowWidth: 0,
                btWidth: 0,
                btHeight: 0,
                series: [
                    {
                        type: 'rangeArea',
                        name: 'Team B Range',

                        data: [
                            {
                                x: 'Jan',
                                y: [1100, 1900]
                            },
                            {
                                x: 'Feb',
                                y: [1200, 1800]
                            },
                            {
                                x: 'Mar',
                                y: [900, 2900]
                            },
                            {
                                x: 'Apr',
                                y: [1400, 2700]
                            },
                            {
                                x: 'May',
                                y: [2600, 3900]
                            },
                            {
                                x: 'Jun',
                                y: [500, 1700]
                            },
                            {
                                x: 'Jul',
                                y: [1900, 2300]
                            },
                            {
                                x: 'Aug',
                                y: [1000, 1500]
                            }
                        ]
                    },

                    {
                        type: 'rangeArea',
                        name: 'Team A Range',
                        data: [
                            {
                                x: 'Jan',
                                y: [3100, 3400]
                            },
                            {
                                x: 'Feb',
                                y: [4200, 5200]
                            },
                            {
                                x: 'Mar',
                                y: [3900, 4900]
                            },
                            {
                                x: 'Apr',
                                y: [3400, 3900]
                            },
                            {
                                x: 'May',
                                y: [5100, 5900]
                            },
                            {
                                x: 'Jun',
                                y: [5400, 6700]
                            },
                            {
                                x: 'Jul',
                                y: [4300, 4600]
                            },
                            {
                                x: 'Aug',
                                y: [2100, 2900]
                            }
                        ]
                    },

                    {
                        type: 'line',
                        name: 'Team B Median',
                        data: [
                            {
                                x: 'Jan',
                                y: 1500
                            },
                            {
                                x: 'Feb',
                                y: 1700
                            },
                            {
                                x: 'Mar',
                                y: 1900
                            },
                            {
                                x: 'Apr',
                                y: 2200
                            },
                            {
                                x: 'May',
                                y: 3000
                            },
                            {
                                x: 'Jun',
                                y: 1000
                            },
                            {
                                x: 'Jul',
                                y: 2100
                            },
                            {
                                x: 'Aug',
                                y: 1200
                            },
                            {
                                x: 'Sep',
                                y: 1800
                            },
                            {
                                x: 'Oct',
                                y: 2000
                            }
                        ]
                    },
                    {
                        type: 'line',
                        name: 'Team A Median',
                        data: [
                            {
                                x: 'Jan',
                                y: 3300
                            },
                            {
                                x: 'Feb',
                                y: 4900
                            },
                            {
                                x: 'Mar',
                                y: 4300
                            },
                            {
                                x: 'Apr',
                                y: 3700
                            },
                            {
                                x: 'May',
                                y: 5500
                            },
                            {
                                x: 'Jun',
                                y: 5900
                            },
                            {
                                x: 'Jul',
                                y: 4500
                            },
                            {
                                x: 'Aug',
                                y: 2400
                            },
                            {
                                x: 'Sep',
                                y: 2100
                            },
                            {
                                x: 'Oct',
                                y: 1500
                            }
                        ]
                    }
                ],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'rangeArea',
                        animations: {
                            speed: 500
                        }
                    },
                    colors: ['#d4526e', '#33b2df', '#d4526e', '#33b2df'],
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        opacity: [0.24, 0.24, 1, 1]
                    },
                    forecastDataPoints: {
                        count: 2
                    },
                    stroke: {
                        curve: 'straight',
                        width: [0, 0, 2, 2]
                    },
                    legend: {
                        show: true,
                        customLegendItems: ['Team B', 'Team A'],
                        inverseOrder: true
                    },
                    title: {
                        text: 'Range Area with Forecast Line (Combo)'
                    },
                    markers: {
                        hover: {
                            sizeOffset: 5
                        }
                    }
                }
            };
        },
        computed: {
            options: function () {
                let options = []
                for (let dashboard of this.$store.getters.dashboards) {
                    options.push({ "value": dashboard.id, "text": dashboard.name })
                }
                return options
            }
        },
        methods: {
            testAddDb() {
                this.$store.commit('addDashboard')
            },
            testRmvDb() {
                console.log("selected: " + this.selected)
                this.$store.commit('removeDashboard', this.selected)
            },
            testGetData() {
                let endTime = new Date().getTime()
                let startTime = endTime - 10*60*1000
                console.log(getData(startTime, endTime, ['f0eab8d8-caf7-4d69-ba30-0b33245ee169', '2ef4588b-d9c5-40f0-859b-ba1524fee8d3']))
            }
        },
        mounted() {
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
            this.btWidth = this.$refs.bt1.clientWidth;
            this.btHeight = this.$refs.bt1.clientHeight;
            this.$nextTick(() => {
                window.addEventListener('resize', () => {
                    this.windowHeight = window.innerHeight;
                    this.windowWidth = window.innerWidth
                });
            });
            
            
        },
        beforeDestroy() {
            console.log('leaving development page...')
            
        },
        components: {
            apexchart: VueApexCharts,
        }

    }
</script>

<style scoped>
</style>